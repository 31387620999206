import React, { useLayoutEffect } from "react";

const Wrapper = ({ children }) => {
    const mobileResize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    useLayoutEffect(() => {
        mobileResize();
        window.addEventListener("resize", mobileResize);

        return () => {
            window.removeEventListener("resize", mobileResize);
        };
    }, []);
    return (
        <div
            className="h-screen overflow-y-scroll Wrapper"
            style={{ height: "calc(var(--vh, 1vh) * 100)" }}
        >
            <div className="flex justify-center">
                <div className="bg-white WrapperScreen">{children}</div>
            </div>
        </div>
    );
};

export default Wrapper;
