import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

const checkEmailReg = (email) => {
    if (email.trim().length === 0) return;
    const emailReg = /[a-zA-Z0-9._-]+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;
    return emailReg.test(email.trim());
};

const authNav = [
    {
        type: "link",
        label: "계정 찾기",
        url: "/users/find-user-email",
    },
    {
        type: "line",
        label: "",
        url: "",
    },
    {
        type: "link",
        label: "비밀번호 찾기",
        url: "/users/reset-user-password",
    },
    {
        type: "line",
        label: "",
        url: "",
    },
    {
        type: "link",
        label: "회원가입",
        url: "/users/register",
    },
];

export const LoginForm = (props) => {
    const { service_url, error, email: defaultEmail, next } = props;
    const formRef = useRef(null);

    const [email, setEmail] = useState(defaultEmail);
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(error);

    // validation
    const emailIsValid = useMemo(() => checkEmailReg(email), [email]);

    const [pwInputType, setPwInputType] = useState("password");

    useEffect(() => {
        if (email === "") {
            setErrorMessage("");
        }
    }, [email, password, setErrorMessage]);

    const handleClick = useCallback(() => {
        formRef.current.submit();
    }, [formRef]);

    return (
        <section>
            <header className="flex justify-center items-center LogoImgWrapper">
                <a
                    href={`${
                        service_url ? service_url : "https://likelion.net"
                    }`}
                >
                    <img
                        src="../../static/img/likelion_kor_logo.svg"
                        alt="logo"
                        className="LogoImg"
                    />
                </a>
            </header>

            <main>
                {/* kakao button */}
                <section>
                    <form method="post" action="/api/kakao/oauth">
                        <input
                            type="text"
                            name="next"
                            value={next}
                            hidden
                            readOnly
                        />
                        <button
                            type="submit"
                            className="flex w-full items-center justify-center gap-1.5 rounded-lg p-3 outline-none md:gap-2"
                            style={{
                                backgroundColor: "#fee500",
                            }}
                        >
                            <img
                                width={20}
                                height={20}
                                src="../../static/img/kakao_talk.svg"
                                alt="kakaotalk logo"
                            />
                            <span className="text-sm font-semibold text-gray-800 md:text-base">
                                카카오톡으로 1초만에 시작하기
                            </span>
                        </button>
                    </form>
                </section>
                <section className="my-4 flex w-full items-center justify-center">
                    <div
                        className="bg-gray-200"
                        style={{ width: "100%", height: 1 }}
                    />
                    <h4 className="mx-4 flex-shrink-0 text-xs font-semibold text-gray-400">
                        또는
                    </h4>
                    <div
                        className="bg-gray-200"
                        style={{ width: "100%", height: 1 }}
                    />
                </section>
                <form method="post" ref={formRef} className="space-y-4">
                    <input
                        type="text"
                        name="email"
                        className="w-full rounded-md focus:border-gray-500 border border-gray-300 p-3 text-sm text-gray-800 placeholder-gray-400 focus:outline-none md:text-base focus:ring-0"
                        placeholder="이메일"
                        onChange={(e) => setEmail(e.target.value.trim())}
                        value={email}
                    />
                    <div className="relative">
                        <input
                            type={pwInputType}
                            name="password"
                            className="w-full rounded-md border border-gray-300 p-3 text-sm text-gray-800 placeholder-gray-400 focus:border-gray-500 focus:outline-none focus:ring-0 md:text-base"
                            placeholder="비밀번호"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") handleClick();
                            }}
                        />
                    </div>
                    {errorMessage && (
                        <ul className="mt-8 text-gray-500 text-sm ">
                            <li className="flex space-x-2 items-center text-red-450">
                                <p className="text-xs lg:text-sm">
                                    이메일, 비밀번호를 다시 확인해주세요.
                                </p>
                            </li>
                        </ul>
                    )}
                    {/* login button*/}
                    <div>
                        <button
                            type="button"
                            className={`flex w-full items-center justify-center rounded-md py-3 outline-none ${
                                email && password
                                    ? "bg-yellow-450"
                                    : "cursor-not-allowed bg-gray-100 opacity-50"
                            }`}
                            disabled={!email || !password}
                            onClick={handleClick}
                        >
                            <span
                                className={`text-sm font-semibold md:text-base ${
                                    email && password
                                        ? "text-white"
                                        : "text-gray-300"
                                }`}
                            >
                                로그인
                            </span>
                        </button>
                    </div>
                </form>
            </main>
            <footer className="mt-8">
                <nav>
                    <ul className="flex justify-center">
                        {authNav.map(({ type, label, url }, idx) => {
                            return (
                                <li
                                    key={`${type}_${idx}`}
                                    className="flex items-center"
                                >
                                    {type === "link" && (
                                        <a
                                            href={url}
                                            className="text-xs font-medium text-gray-500 outline-none md:text-sm"
                                        >
                                            {label}
                                        </a>
                                    )}
                                    {type === "line" && (
                                        <span className="mx-6 inline-block w-0.5 h-3 bg-gray-300"></span>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </footer>
        </section>
    );
};
